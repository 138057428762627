@@ -0,0 +1,919 @@
<template>
<body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="Christmas2024"
    itemscope
    itemtype="http://schema.org/WebPage"
    >  
    <main class="main-wrap">
      <div class="content-wrap">

        <img src="@/img/branding/Christmas-Email-Signature-Small.jpg">
        
      </div>
    </main>
  </body>
</template>

<script>
export default {  
  mounted() {
  }
};
</script>

<style lang="scss">  
</style>